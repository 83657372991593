'use strict';

module.exports = {
  crc1: require('./crc1'),
  crc8: require('./crc8'),
  crc81wire: require('./crc8_1wire'),
  crc16: require('./crc16'),
  crc16ccitt: require('./crc16_ccitt'),
  crc16modbus: require('./crc16_modbus'),
  crc16xmodem: require('./crc16_xmodem'),
  crc16kermit: require('./crc16_kermit'),
  crc24: require('./crc24'),
  crc32: require('./crc32'),
  crcjam: require('./crcjam')
};
